.buorg {
  background-color: #f5f5f5 !important;
}
.buorg div {
  padding: 11px 12px 11px 40px;
  font-size: 16px;
}
.buorg div a,.buorg div a:visited {
  box-shadow: none !important;
  background: $primary-color !important;
  font-size: 14px;
}

#buorgig{
  background-color: $accent-color-3 !important;
}

@media only screen and (max-width: 700px){
  .buorg div {
    padding:5px 12px 5px 9px;
    text-indent: 22px;
    line-height: 1.3em;
  }
  .buorg {
    background-position: 9px 8px;}
}