.btn-flat {padding: 10px 25px; border-radius: 3px; transition: all 0.2s linear; font-size: 13px; border: 0; text-align: center; margin: 0; cursor: pointer; font-family: "Open Sans";}
.btn-flat .fa {font-size: 13px; display: inline-block; margin-left: 5px;}
.btn-flat:focus {outline: 0;}

//used for send on singleconversation.js, search dropdown, and add resume to group modal
.btn-flat.btn-success {background: $primary-color; color: #ffffff;}
.btn-flat.btn-success:hover {background: $primary-color-dark;}

//only used on register page
.btn-flat.btn-cancel {background: $background-dark; color: $primary-text; margin-right: 15px;}
.btn-flat.btn-cancel:hover {background: $accent-color-3; color: #ffffff;}

//used on inbox singleconversation.js and search dropdown
.btn-flat.btn-reset {color: $secondary-text; margin-right: 15px; background: none;}
.btn-flat.btn-reset:hover {color: $accent-color;}

//used for resume right panel and group members invite
.btn-flat.btn-sidebar {background: $primary-color; color: #ffffff; margin: 0; display: inline-block;}
.btn-flat.btn-sidebar:hover {background: $accent-color-3; color: #ffffff;}


.btn-sidebar-create {
  color: $secondary-text; padding: 10px 15px; font-size: 11px; cursor: pointer; margin-bottom: 20px; border-radius: 3px; transition: all 0.2s linear;

  .btn-icon-left {margin-right: 10px; color: $accent-color-2;}
  .fa-arrow-left {margin-right: 5px;}
  .fa-arrow-right {margin-left: 5px;}
}
.btn-sidebar-create:hover {color: $accent-color;}

//resume sidebar right - go to ideas page
.btn-text {cursor: pointer; color: $secondary-text; display: block; text-align: center; font-size: 11px; margin-top: 10px !important;}
.btn-text:hover {color: $accent-color;}

//used for verification on account settings, and resend email on register
.btn-flat.btn-confirmation {display: inline-block; padding: 10px; font-size: 12px;}

//create resume sidebar button container
.btn-resume-save {border-bottom: 1px solid $divider; margin-bottom: 30px; padding-bottom: 15px;}

//used for login, register, forgotPassword, etc.
.btn-loader {position: relative; text-align: center; display: block; min-height: 44px; line-height: 24px; width: 100%;}

//pink button with blue hover
.btn-primaryAccent {background: $accent-color; color: #ffffff;}
.btn-primaryAccent:hover {background: $primary-color;}
.btn-primaryAccent a {text-decoration: none; color: #ffffff; display: block;}

//blue button with pink hover
.btn-accentPrimary {background: $primary-color; color: #ffffff;}
.btn-accentPrimary:hover {background: $accent-color;}

//gray button with pink hover
.btn-secondary {background: $background-dark-medium; color: $secondary-text; margin-bottom: 15px;}
.btn-secondary:hover {background: $accent-color; color: #ffffff;}
a.btn-secondary {text-decoration: none; display: block;}
button.btn-secondary {width: 100%;}

.btn-center {display: block !important; margin: 0 auto; width: 50%;}

//not sure if were using, its on createNewResume and createOptionsPage
.btn-link {text-decoration: none; color: $secondary-text; display: block; text-align: center;}
.btn-link:hover {color: #fff; text-decoration: none;}

//also on createOptionsPage
.btn-disabled:hover {background-color: $background-dark !important; color: $secondary-text !important; cursor: default !important;}


//on sidebarconversations.js but not sure how to see it
.btn-viewResumes {display: inline-block; margin: 0 auto;}
.btn-viewResumes p {display: inline-block; margin-bottom: 0; font-size: 11px; color: $primary-color; margin-left: 10px; font-weight: 600;}
.btn-viewResumes p:hover {color: $accent-color; cursor: pointer;}

.btn-idea {font-size: 12px; cursor: pointer; color: $secondary-text; margin: 0; transition: all 300ms linear; margin-top: 5px; text-align: left; padding: 3px 10px;}
.btn-idea:hover {color: $accent-color; background: $background-dark-medium; border-radius: 3px;}

//on createNewResume... not sure where that page is
.btn-link {text-decoration: none; padding: 10px 25px; border-radius: 3px; transition: all 0.2s linear; font-size: 13px; cursor: pointer; display: inline-block;}
.btn-link.btn-link-secondary {background: $background-dark-medium; color: $secondary-text;}
.btn-link.btn-link-secondary:hover {background: $background-dark;}

//not sure how to see this one either
.btn-notification {background: #fff; padding: 5px 15px; border-radius: 3px; margin-left: 10px; color: $secondary-text; font-size: 12px; transition: all 300ms linear;}
.btn-notification:hover {background: $background-dark; cursor: pointer;}


.btn-fullWidth {width: 100%;}

/***** REACT BS EXPORT CSV BTN *****/
.react-bs-table-csv-btn {
  background: $primary-color;
  display: inline-block;
  width: 120px !important;
  border-radius: 3px !important;
  margin-bottom: 15px;
  color: white;
  padding: 10px 15px !important;
  font-size: 12px !important;
  border: none;
  cursor: pointer;
}
.react-bs-table-csv-btn:hover {background: $accent-color;}
.react-bs-table-csv-btn:focus {border: 0; outline: 0;}

/***** SWEET ALERT BTN STYLES *****/
.sweet-alert p span {display: inline-block; width: 45%; margin: 0 2.5%;}

.sweet-alert .btn-danger {
  background: $primary-color;
  color: #ffffff;
  border: 0;
  padding: 8px 15px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 3px;
  transition: all 0.2s linear;
  margin: 10px 0;
  width: 100%;
}
.sweet-alert .btn-danger:hover {background: $accent-color; color: #ffffff;}

.sweet-alert .btn-default {
  background: $background-dark;
  color: $secondary-text;
  border: 0;
  padding: 8px 0;
  font-weight: 600;
  font-size: 13px;
  border-radius: 3px;
  transition: all 0.2s linear;
  margin: 10px 20px 10px 0 !important;
  min-width: 64px;
  width: 100%;
}
.sweet-alert .btn-default:hover{background: $background-medium; color: $accent-color;}

/***** DISABLED BTN *****/
button[disabled] {opacity: .7;}

/***** TRANSPARENT BTN *****/
.btn-transparent {background: transparent;}

