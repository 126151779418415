.page-login{background: $primary-color;}

.page-account {
	min-height: 100%;

	.page-account-container {
		width: 35%; max-width: 400px; margin: 0 auto;
		.page-account-logo {width: 200px; margin: 0 auto 45px auto; display: block; padding-top: 25%;}
	}
	.page-account-container-large {
		width: 100%; max-width: 700px; margin: 0 auto;
		.page-account-logo {width: 200px; margin: 0 auto 30px auto; display: block; padding-top: 5%;}
	}

	.page-account-content {background-color: #fff; padding: 7%; border-radius: 3px; margin-bottom: 25px;}

	.page-account-text-link {
		font-size: 11px;
		color: $primary-color;
		display: block;
		padding: 0 0 25px 0;
		margin: 0;

		a {color: $primary-color;}
		a:hover {color: $accent-color; text-decoration: underline;}
	}
	.page-account-text-link.page-account-text-link-light {
		color: #ffffff;
		font-size: 14px;

		a {color: #ffffff;}
		a:hover {color: #ffffff; text-decoration: underline;}
	}

	.page-account-text-link-under-input {
		margin-top: -18px;

		font-size: 11px;
		color: $primary-color;
		display: block;
		padding: 0 0 25px 0;

		a {color: $primary-color;}
		a:hover {color: $accent-color; text-decoration: underline;}
	}

	.form-message-bottom {font-size: 12px; color: $accent-color-2; padding: 20px 30px 0 30px; font-weight: 600;}
	.form-message-bottom-resend {display: block; margin-top: 15px; color: $primary-text;}
	.form-message-bottom-resend:hover {text-decoration: underline; cursor: pointer;}
	.form-message-bottom-terms {
		font-size: 10px; color: $secondary-text; padding-top: 15px;

		a {color: $secondary-text;}
		a:hover {text-decoration: underline; color: $primary-color;}
	}

	.account-message {
		background: #1976d2;
		border-radius: 3px;
		padding: 10px;
		margin-bottom: 15px;
		color: #fff;
		text-align: center;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 600;
		letter-spacing: 2px;
	}

	.rw-state-focus {border: none !important; border: 0 !important;}
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
	.page-account {
		.page-account-container {width: 90%; margin: 0 auto;}
		.page-account-container-large {width: 90%; margin: 0 auto;}
	}
}

.new-account{
    text-align: center;
    margin-top: 40px;
    color: #fff;
}

.forgot-password-box{
	@extend .page-account-container;
	height: 275px;
}

.forgot-password-directions{
	width: 80%;
	margin-right: auto;
	margin-left: auto;
}

.back-to-login{
	@extend .new-account;
}

.reset-password-box{
	@extend .page-account-container;
	height: 325px;
}