.options-box-container {padding: 0 10px;}

.option-box {
  background-color: #fff;
  max-width: 300px;
  margin: 15px auto;
  border: 1px solid $background-dark;
  border-radius: 3px;
  padding: 55px 20px;
  height: 315px;

  i {color: $accent-color; font-size: 60px;}
  .btn-center {width: 150px;}
  .welcome-feature-name {
    text-align: center; padding-top: 20px;
    p {padding: 0  10%;}
  }
  .feature-title {color: $accent-color-3; font-size: 18px; font-weight: 600; margin-bottom: 35px;}
}

@media (min-width: 768px){
  .mui-container.sub-header-content {max-width: 900px;}
}

@media only screen and (max-width: 900px){
  .option-box i {font-size: 45px;}
  .feature-title {font-size: 15px;}
}

@media (min-width: 544px){
  .mui-container.sub-header-content {max-width: 800px;}
}

@media only screen and (max-width: 720px){
  .options-box-container {width: 100% !important;}
}