.iframe-wrapper {
  width:100%;
  //max-width:600px;
  height:100%;
  margin:0 auto;
  background-color:#cfcfcf;
  border:1px solid red;
}

.h_iframe {
  position:relative;
  padding-top: 56%;
}

.h_iframe iframe {
  position:absolute !important;
  top:0;
  left:0;
  width:100%;
  height:100%;
}