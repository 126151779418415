/****** BG COLORS *****/
$background-light: #fcfcfc;
$background-medium: #f5f5f5;
$background-dark: #e4e3e3;
$background-dark-medium: #e8e8e8;

/****** DIVIDER & BORDER COLORS *****/
$divider: #e1e1e1;

/****** HOVER / ACTIVE COLORS *****/
$hover-color: #f5f5f5;
$active-color: #e3f1fd;

/****** MAIN & ACCENT COLORS *****/
$primary-color: #2196f3;
$primary-color-dark: #1976d2;
$accent-color: #f03b74;
$accent-color-2: #e3b600;
$accent-color-3: #727272;

/****** TEXT COLORS *****/
$primary-text: #333333;
$secondary-text: #727272;
$light-gray-text: rgba(0, 0, 0, .4);
$placeholder-text: #c7c7cd;

/***** IDEAS COLORS *****/
$suggested: #2095F2;
$researching: #3E50B4;
$busy: #FEC006;
$said-yes: #4BAE4F;
$said-no: #E81D62;
$waiting: #9d9d9d;