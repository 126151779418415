/***** GENERAL HEADER STYLES *****/
#header {background: $primary-color; position: fixed; width: 100%; z-index: 1002; height: 60px; box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);}
#header p {display: inline-block;}

@media only screen and (max-width: 720px) {
	#header {height: 84px; box-shadow: none !important;}
}


/***** HEADER GRID STYLES *****/
.sidebar-left {position: absolute; left: 0; width: 350px; padding: 0 20px; height: 60px; line-height: 60px; z-index: 5;}
.content-main {position: absolute; left: 350px; right: 350px;}
.sidebar-content {position: absolute; right: 0; width: 350px;}

@media only screen and (min-width: 950px) and (max-width: 1200px){
  .sidebar-left {width: 260px;}
  .content-main {left: 260px; right: 260px;}
  .sidebar-content {width: 260px;}
}

@media only screen and (min-width: 720px) and (max-width: 950px) {
  .sidebar-left {width: 190px;}
  .content-main {left: 190px; right: 190px;}
  .sidebar-content {width: 190px;}
  .header-right .menu-large-item {margin: 0 3px !important;}
  .header-logo {max-width: 100%; margin-top: 4px !important;}
}

@media only screen and (max-width: 720px) {
	.sidebar-left {position: relative; left: auto; width: 100%; z-index: 5; height:auto; padding: 3px; box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);}
	.content-main {position: relative; left: auto; right: auto; background: $primary-color-dark;}
}


/***** HEADER LOGO STYLES *****/
.header-logo {width: 175px; display: block; padding-top: 4px; margin: 0 auto;}

@media only screen and (max-width: 720px){
	.header-logo {width: 175px; padding-top: 0;}
}


/***** HEADER PAGE LINKS STYLES *****/
.header-menu-main ul {margin: 0; padding: 0; text-align: center;}
.header-menu-main li {display: inline-block;}
.header-menu-main li a {
	display: block;
	margin:0 20px;
	border-bottom: 2px solid $primary-color;
	color: #ffffff;
	padding: 23px 15px 15px 15px;
	text-decoration: none;
}
.header-menu-main li:last-child a {margin-right: 0;}
.header-menu-main li a.active {border-bottom: 2px solid #ffffff;}
.header-menu-main li a .count {
	color: #ffffff;
	display: inline-block;
	font-size: 10px;
	font-weight: 600;
	width: 5px;
	height: 5px;
	background: $accent-color;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	text-align: center;
	vertical-align: super;
	margin-bottom: 3px;
	margin-left: 2px;
}

@media only screen and (max-width: 1240px) {
  #header .header-menu-main li a {padding: 19px 8px !important; margin: 0 16px !important;}
  #header .header-menu-main li:last-child {padding: 19px 8px !important; margin: 0 16px !important;}
}

@media only screen and (max-width: 1150px) {
  #header .header-menu-main li a {padding: 19px 8px !important; margin: 0 8px !important;}
  #header .header-menu-main li:last-child {padding: 19px 8px !important; margin: 0 8px !important;}
}

@media only screen and (max-width: 800px) {
  #header .header-menu-main li a {padding: 19px 4px !important; margin: 0 4px !important; font-size: 13px; height: 60px;}
  #header .header-menu-main li:last-child {padding: 19px 4px !important; margin: 0 4px !important; font-size: 13px;}
}

@media only screen and (max-width: 768px) {
  #header .header-menu-main li a {margin: 0;}
}

@media only screen and (max-width: 720px) {
	#header .header-menu-main > ul {padding: 8px 0; display: flex; justify-content: center; align-items: center;}
	#header .header-menu-main li a {border-bottom: none !important; font-size: 12px; padding: 0 !important; height: auto;}
	#header .header-menu-main li:last-child {padding: 0 !important;}
	#header .header-menu-main li a.active {background: rgba(255, 255, 255, .3); padding: 4px 8px !important; border-radius: 2px; height: auto; transition: background 300ms linear;}
	.header-menu-main .header-dropdown span {font-size: 12px !important;}
}


/***** HEADER CREATE STYLES *****/
.header-menu-main .header-dropdown {display: inline-block; width: auto; color: #fff; padding-left: 16px; border-left: 1px solid #fff;}
.header-menu-main .header-dropdown span {vertical-align: middle; margin-top: -4px; margin-right: 4px; font-size: 14px; width: 32px; text-align: center;}
.header-menu-main .header-dropdown ul {text-align: left;}
.header-menu-main .header-dropdown li a {color: #727272; display: block; padding: 10px 10px 10px 20px !important; border-bottom: none; margin: 0 !important; width: 160px; height: auto;}
.header-menu-main .header-dropdown li:last-child {padding: 0 !important; margin: 0 !important; height: auto;}
.header-add-dropdown.header-dropdown-box{
	width: 160px; right: -60px; top: 32px; background-color: #fcfcfc;
	.arrow-up {margin-right: 70px;}
}
.header-dropdown-list {height: auto; right: -13px;}
#header .add-dropdown-content{
	text-align: left; color: $secondary-text; list-style: none; padding: 10px 0 !important;
	li {
		font-size: 12px;
		a {text-decoration: none; color: $secondary-text; display: block; padding: 10px 10px 10px 15px !important; margin: 0 !important; height: auto; transition: all 300ms linear;}
		i {margin-right: 8px; vertical-align: middle; min-width: 25px; color: $secondary-text; font-size: 18px; text-align: center; cursor: pointer;}
		&:hover a {background: $accent-color; color: #fff !important; cursor: pointer;}
		&:hover i {color: #fff;}
	}
	li:last-child {padding: 0 !important; margin: 0 !important;}
}

@media only screen and (max-width: 450px){
	.header-add-dropdown.header-dropdown-box{
		right: -8px;
		.arrow-up {margin-right: 16px;}
	}
}
/***** HEADER RIGHT STYLES *****/
.header-right {padding-top: 18px; margin-right: 20px; text-align: center;}
.header-right .header-icon {color: #ffffff; vertical-align: middle;}
.header-right img {vertical-align: baseline;}
.header-right .menu-large-item {display: inline-block; margin: 0 8px; vertical-align: top; height: 24px; width: 24px;}
.header-right .menu-large-item .header-icon {transition: all 300ms linear;}
.header-right .menu-large-item .header-icon:hover {transform: scale(1.3);}
.header-right .header-icon.icon-dark {color: $primary-color; background: #fff; border-radius: 3px; width: 19px; height: 18px; cursor: pointer; padding-top: 2px; margin-top: 2px;}

/***** HEADER DROPDOWN STYLES *****/
.header-dropdown{position: relative; cursor: pointer; height: 22px; width: 24px;}
.header-dropdown:first-of-type i {font-size: 19px; padding-top: 2px;}
.dropdown-active .header-dropdown-box {display: block;}
.header-dropdown-box {
  display: none; position: absolute; right: -8px; top: 40px; z-index: 5; background-color: $background-medium; width: 255px; margin-left: 15px; border-radius: 3px; box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);
  .arrow-up {width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-bottom: 10px solid #fcfcfc; margin-top: -10px; float: right; margin-right: 10px;}
}
.header-dropdown-content-header {
	background-color: #fcfcfc;
	border-bottom: 1px solid $divider;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
	height: 50px;
	line-height: 50px;

	.header-dropdown-content-title {color: $accent-color; margin-bottom: 0; font-size: 16px; font-weight: 600; text-align: center; margin-top: 0;}
	.header-dropdown-content-subtitle {font-size: 12px;}
}
.header-dropdown-content-create a {color: $primary-color; transition: all 300ms linear;}
.header-dropdown-content-create a:hover {cursor: pointer;}
.header-dropdown-content {
  padding: 20px; height: auto; right: -13px;
  input {width: 100% !important;}
  .share-btn {margin-top: 10px; background-color: $accent-color !important; color: #fff !important; width: 100% !important; height: 32px; line-height: 32px; padding: 0;}
}
.header-dropdown-content-title {font-size: 20px; font-weight: 600;}
.header-dropdown-content-subtitle {color: $primary-text;}
.header-dropdown-box form {text-align: left;}


/***** NOTIFICATIONS ICON  STYLES *****/
#header {
	.react-notification-center .r-notifications-icon {border: none; width: auto; min-width: 20px; height: 20px; padding: 0 4px; top: 3px; color: $primary-color-dark; background-color: #fff; border-radius: 3px; font-size: 9px;}
	.react-notification-center .r-notifications-icon:before {content: "0";}
	.react-notification-center .r-notifications-icon.active {background: $accent-color; color: #fff;}
	.react-notification-center .r-notifications-icon.active:before {content: "";}
	.react-notification-center .rr-wrapper:before {border-right: 10px solid transparent; border-left: 10px solid transparent; border-bottom: 10px solid #fcfcfc; top: -10px; left: auto; right: 12px;}
	.react-notification-center .rr-wrapper {width: 285px !important; height: 348px; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)}
	.react-notification-center .rr-wrapper.left {left: auto; right: -10px;}
	.react-notification-center .rr-wrapper .notification-holder .rn-content {max-height: 260px;}
	.react-notification-center .rn-header h4 {color: $accent-color;}
}


/***** NOTIFICATIONS LIST STYLES *****/
.r-notifications {
	.rn-content .rn-ul {margin-bottom: 0;}
	.rn-content li.rn-item {padding: 8px 15px; color: #727272; list-style: none; height: 80px;}
	.rn-content li.rn-item:before {background-color: $accent-color; margin-top: -6px;}
	.rn-content li.rn-item .short-desc {margin: 0 0 0 15px; font-size: 11px; text-align: left; width: 90%; line-height: 16px;}
	.rn-content li.rn-item .short-desc .notification-tag {width: 47px; font-size: 8.5px; padding: 3px 8px; margin-bottom: 2px; background-color: $primary-color;}
	.rn-content li.rn-item .short-desc .date {margin-top: 2px;}
	.rn-content .desc {color: #727272;}
}


/***** ACCOUNT DROPDOWN STYLES *****/
.my-account-header-section{
	text-align: center; padding: 15px;
	.initials {height: 50px; width: 50px; border-radius: 3px; display: block; background: $accent-color-3; margin: 0 auto; text-align: center; padding-top: 15px; color: white; margin-bottom: 20px;}
	h3 {
		color: $accent-color; font-weight: 600; margin-top: 0; margin-bottom: 0; padding-bottom: 0;
		.fa {color: $primary-color; margin-left: 5px; vertical-align: baseline;}
	}
	.my-account-email {color: $secondary-text; margin-top: 0; font-size: 12px;}
}
.add-my-resume {
	display: inline-block;
	margin-bottom: 0;
	text-align: center;
	text-transform: uppercase;
	border-radius: 3px;
	padding: 2px 6px;
	font-size: 11px;
	margin-left: 5px;
	background: #2196f3;
	color: #fff;
}
.my-account-footer-section {
	ul {list-style: none; padding: 0;}
	li {font-size: 12px; text-align: left; padding: 0; border-top: 1px solid $divider; transition: all 300ms linear;}
	li:last-child {padding: 10px 30px;}
	li a {text-decoration: none; color: $primary-text; padding: 10px 30px;}
	li:hover {background: $accent-color; color: #fff; cursor: pointer;}
	li:hover .fa, li:hover a {color: #fff;}
	li .fa {color: $primary-text; font-size: 12px; margin-right: 10px;}
	li a {display: block;}
}


/***** MOBILE MENU STYLES *****/
.menu-xs {display: none; color: #fff;}
.menu-xs .fa-bars {cursor: pointer;}
.notifications-mobile {display: none;}

.mobile-menu-dropdown-box {@extend .header-dropdown-box; right: -14px; z-index: 10001; width: 225px; top: 50px; background-color: #fcfcfc;}
.mobile-menu-dropdown-box .header-dropdown-content {padding: 0;}
.mobile-menu-dropdown-box ul {padding-left: 0; list-style: none; text-align: left; color: $secondary-text; margin-bottom: 0; margin-top: 0;}
.mobile-menu-dropdown-box ul li {min-height: 48px; height: auto; line-height: 32px; border-top: 1px solid $divider; padding: 8px 16px; cursor: pointer; font-size: 13px;}
.mobile-menu-dropdown-box ul li:first-child {border-top: none;}
.mobile-menu-dropdown-box ul li a {text-decoration: none; color: $secondary-text;}
.mobile-menu-dropdown-box ul li i {width: 32px; padding-right: 8px; text-align: center; vertical-align: top; margin-top: 8px; font-size: 16px;}
.mobile-menu-dropdown-box ul li span {width: 32px; padding-right: 8px; text-align: center; vertical-align: top; margin-top: 8px; font-size: 16px;}

.mobile-menu-dropdown-box ul li:hover {background: $accent-color; color: #fff;}
.mobile-menu-dropdown-box ul li:hover a {color: #fff;}

.mobile-menu-dropdown-box ul li.submenu-menu-active:hover {background: transparent; color: $secondary-text;}

.invite-submenu input {margin-top: 0; height: 32px;}
.invite-submenu .btn-flat {padding: 0 8px; margin-top: 8px;}

.share-submenu input {margin-top: 0; height: 32px; margin-bottom: 8px !important;}
.share-submenu .btn-flat {padding: 0 8px; margin-top: 8px}

.add-menu-submenu {padding-left: 10px !important;}
.add-menu-submenu:hover {color: blue;}
.add-menu-submenu li {border-top: none !important; min-height: 16px !important; padding: 0 16px !important;}
.add-menu-submenu li  a {cursor: pointer; text-decoration: none !important;}

.add-menu:hover ul li a {color: $secondary-text;}
.add-menu:hover ul li:hover {background-color: transparent;}
.add-menu:hover ul li:hover a {color: $accent-color;}

.header-submenu {display: none;}
.submenu-menu-active .header-submenu {display: block;}

@media only screen and (max-width: 720px){
	.menu-xs {position: absolute; top: -6px; right: 20px; color: #fff; display: block;}
	.menu-xs i {font-size: 19px;}
	.notifications-mobile {position: absolute; right: 42px; top: 10px; display: block;}
}






