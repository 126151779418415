/***** HEADER STYLES *****/
.header-link {display: block; margin: 0 20px; border-bottom: 2px solid $primary-color; color: #fff; padding: 23px 15px 15px 15px; text-decoration: none;}
.header-link-active {border-bottom: 2px solid #fff;}
.header-icon {color: #fff; vertical-align: middle;}
.header-icon-resize {font-size: 17px; margin-top: 3px;}

/***** SIDEBAR STYLES *****/
.sidebar-title {font-size: 14px; line-height: 17px; color: $secondary-text; font-weight: 600;}
.sidebar-text {margin-bottom: 0; line-height: 16px;}
.sidebar-details {font-size: 10px; font-family: 'Open Sans'; color: $secondary-text; font-style: italic;}
.sidebar-resume-name {color: $primary-color; font-weight: bold; font-size: 28px;}
.sidebar-resume-details {display: inline-block; background: #e8e8e8; border-radius: 3px; margin-left: 10px; padding: 0 5px; font-size: 9px; text-transform: uppercase; letter-spacing: .5px; line-height: 17px; vertical-align: middle;}
.sidebar-resume-text {font-size: 13px;}
.sidebar-section-title {font-size: 13px; font-weight: bold; padding-bottom: 10px; line-height: 15px; margin-bottom: 0; margin-top: 0; text-transform: capitalize;}

/***** EMPTY STATE STYLES *****/
.empty-state-title {font-size: 15px; color: $secondary-text; font-weight: 600;}
.empty-state-subtitle {font-size: 12px; color: $secondary-text; font-weight: 600;}

/***** RESUME HEADER STYLES *****/
.resume-header-btn {background: $primary-color; color: #fff; padding: 5px 7px; border-radius: 3px; transition: all 300ms linear;}
.resume-header-icon {margin-right: 5px; font-size: 14px; margin-bottom: 0;}

/***** PAGE SUBHEADER STYLES *****/
.subheader-action {font-weight: 600;}
.subheader-action:hover {color: $primary-color;}

/***** RESUME STYLES *****/
.resume-name {color: $accent-color; margin-bottom: 10px; margin-top: 12px; background: transparent; font-size: 28px; font-weight: bold;}
.resume-fullName {font-size: 16px; vertical-align: middle; font-weight: 400; margin-left: 10px;}
.resume-section-title {font-size: 15px; font-weight: 600; color: $primary-text; display: block; line-height: 24px; margin-bottom: 0; padding-bottom: 0; text-transform: uppercase;}
.resume-text {line-height: 22px;}
.resume-left-column {min-width: 170px; font-size: 12px; vertical-align: top; font-weight: 600;}

/***** INBOX STYLES *****/
.inbox-header {font-size: 20px; font-weight: 600; text-transform: capitalize; margin-top: 15px; margin-bottom: 0;}
.inbox-content {white-space: pre-wrap;}
.inbox-received {text-align: right; font-size: 10px; font-style: italic;}

/***** IDEAS STYLES *****/
.ideas-column-title{margin-bottom: 10px; font-size: 18px; text-align: left; font-weight: 600; margin-left: 16px; color: #f03b74;}
.ideas-column-title span {color: #ffffff; display: inline-block; margin-left: 6px; margin-top: -1px; font-size: 10px; font-weight: 600; min-width: 15px; height: 15px; background: $accent-color-3; -moz-border-radius:7.5px; -webkit-border-radius: 7.5px; border-radius: 3px; text-align: center; vertical-align: middle;}
.ideas-card-title {margin-bottom: 2px; font-size: 14px; line-height: 17px; text-transform: capitalize; font-weight: 600; padding-top: 3px;}
.ideas-card-details {margin-right: 10px; font-size: 12px; font-family: "Open Sans";}

/***** GROUP STYLES *****/
.group-name {color: #f03b74; margin-bottom: 10px; margin-top: 12px; background: transparent;}
.group-status {display: inline-block; margin: 0 20px 0 0; color: $secondary-text;}
.group-section-header {color: $primary-color; font-size: 15px; font-weight: 600; display: block; line-height: 24px; text-transform: uppercase !important; border-bottom: 2px solid $divider; padding-bottom: 5px; margin-bottom: 15px !important;}
.column-title {border-bottom: 1px solid $divider; text-align: left; padding: 15px 7% 15px 7%; background: $background-dark-medium;}
.column h5 {margin-bottom: 0; font-weight: 600; color: $primary-color; font-size: 12px;}

/***** GENERAL TEXT STYLES *****/
.inline {display: inline-block;}
.bold {font-weight: 600;}
.secondary {color: $secondary-text;}
.accent-text {color: $accent-color-2;}
.fa-size-small {font-size: 17px;}
.secondary-title {text-transform: uppercase; letter-spacing: 1px; text-align: center; font-size: 12px; font-weight: 600; color: $accent-color-2;}
.small-text {font-size: 13px;}

/***** HEADER TEXT STYLES *****/
h2 {font-weight: bold; font-size: 28px; margin: 15px 0 20px 0;}
h3 {font-size: 13px; text-transform: uppercase; padding-bottom: 10px; font-weight: bold; line-height: 15px;}
h4 {padding-bottom: 10px;}

/***** LINE THROUGH STYLES *****/
.linethrough-container {text-align: center; border-top: 1px solid $divider; margin-top: 15px; display: block;}
.linethrough-container p {width: 180px; background: $background-medium; margin: -18px auto 0 auto; text-transform: uppercase;}

.preserve-whitespace {white-space: pre-wrap;}