.register-page{
	@extend .page-login;
}

.register-box{
	@extend .page-account-container;
	width: 750px;
	height: auto;
	overflow: auto;
}

.register-left-side{
	background-color: #f8f8f8;
	height: 100%;
    border-radius: 3px 0 0 3px;
    border-right: 1px solid $divider;
    .has-error{
    	font-size: 12px;
    	padding-left: 35px;
    }
}

.phone {float: left;}

.login-here{
	@extend .new-account;
	a{
		color: #fff;
		text-decoration: underline;
	}
}

.register-radios .rw-widget-input {box-shadow: none;}
.register-radios .rw-widget-input ul li {padding-left: 0;}