/********** GROUPS - GENERAL **********/
.group-status {color: $secondary-text;}
.group-status-pending {color: $accent-color-2;}

/***** TABS STYLES *****/
.panel-actions.panel-actions-bar {
  border-radius: 3px 3px 0 0; border-bottom: 1px solid #fff;

  .panel-action {background: $background-dark-medium;}
  .panel-action.active {background: #fff;}
  .panel-action:first-child.active {border-radius: 3px 0 0 0;}
  .panel-action:last-child.active {border-radius: 0 3px 0 0;}
  .panel-action:first-child:hover {border-radius: 3px 0 0 0;}
  .panel-action:last-child:hover {border-radius: 0 3px 0 0;}
}

/***** ABOUT TABS STYLES *****/
.groupPage{
  .mui-col-md-7 {width: 58%; float: left;}
  .mui-col-md-4 {width: 33%; float: right;}
}

@media only screen and (max-width: 800px){
  .groupPage{
    .mui-col-md-7 {width: 100%; float: none;}
    .mui-col-md-4 {width: 100%; float: none;}
  }
}

/***** RESUMES TAB STYLES *****/
.sidebar-tab .react-bs-table-container {overflow: scroll;}
.sidebar-tab .react-bs-container-header .table{position: static; width: 100%;}
.sidebar-tab .react-bs-container-body {margin-top: 0;}
thead {padding: 10px;  font-size: 12px; font-weight: 600; color: $secondary-text;}
th {border: 0 !important;}
tbody tr {border-bottom: 1px solid $divider;}
tbody tr:last-child {border: 0;}
tr img {width: 38px; border-radius: 3px;}
tr h5 {font-size: 15px; margin: 0;}
tbody tr .resume-list-item-details {margin: 0; padding: 0;}
tbody tr .resume-list-item-details li {display: inline-block; list-style: none; margin-right: 5px; font-size: 13px;}
tbody tr p {font-weight: 600; font-size: 13px;}
.action-text {float: left; margin:0 5px; border-radius: 3px; min-width: 40px; text-align: left; color: $secondary-text; padding-top: 4px; padding-bottom: 4px; width: 100%;}
.action-text:hover {color: $primary-color; cursor: pointer;}
.group-table-actions > .action-text:first-child {margin-left: 0;}
.table-image {width: 50px;}
.table-details {width: 100px;}
.list-item .btn-secondary {width: auto; padding: 4px 8px; margin-right: 8px;}
.list-item-info-button {padding: 3px; background: $background-dark-medium; color: $accent-color-3; display: inline-block; border-radius: 3px; width: 47%; margin: 10px 1.5%; text-align: center; transition: all 300ms linear;}
.list-item-info-button:hover {background: $primary-color; color: #fff; cursor: pointer;}

/***** MEMBERS TAB STYLES *****/
.column {
  border-radius: 3px; display: inline-block; vertical-align: top; width: 30%; margin: 1.5%; transition: all 300ms linear; position: relative;

  .mobile-members-open {display: none; position: absolute; top: 32px; right: 24px; color: $secondary-text; cursor: hand;}
  .column-content {overflow-y: scroll; max-height: 500px; padding:15px 8% 5% 8%; background: $background-dark-medium; min-height: 275px;}
  .column-content-message {padding: 5%; font-weight: 600; color: $accent-color; text-align: center;}
  .message-sidebar-empty {margin-top: 60px;}
  h3 {color: $secondary-text; border-bottom: 1px solid $divider; text-align: left; padding: 15px 7% 15px 7%; background: $background-dark-medium;}
  h5 {margin-bottom: 0; font-weight: 600; color: $primary-color; font-size: 12px;}
  .btn-secondary {background: $primary-color; color: #fff;}
}

@media only screen and (max-width: 1000px){
  .column {width: 100%;}
  .column-content {opacity: 0; height: 0; min-height: 0 !important; padding: 0 !important; transition: opacity 300ms linear;}
  .column.member-menu-active .column-content {opacity: 1; height: auto; padding: 15px 8% 5% 8% !important;}
  .column .mobile-members-open {display: block;}
}

/***** GROUP MODAL FORM STYLES *****/
#form .group-modal-form .mui-row {margin-bottom: 30px;}
.group-modal-form {overflow: visible !important;}
.group-modal-form .rw-dropdown-list {padding-top: 4px;}
.group-modal-form .rw-dropdownlist > .rw-input {padding-top: 4px;}
.group-modal-form .rw-popup-container {top: 40px !important;}
.group-modal-form .rw-select {padding-top: 5px;}
.groupPage .group-message {margin-top: 20px; font-weight: 600; text-align: center;}
.groupImage {width: 40px; height: 40px; border-radius: 3px;}

/***** GET VERIFIED PANEL STYLES *****/
.verify-panel {width: 80%; margin: 0 auto;}
.verify-panel .panelContainer {border-bottom: none;}
.verify-panel h3 {color: $accent-color;}
.verify-panel p {margin-bottom: 8px;}

@media only screen and (max-width: 992px){
  .verify-panel {width: 90%;}
}

@media only screen and (max-width: 768px){
  .verify-panel {width: 100%;}
}

/***** CREATE GROUP STYLES *****/
.group-owner-email {width: 91.7% !important;}
.group-radios {padding-top: 10px;}
.group-radios label {margin-right: 16px;}