.search-form {padding: 20px 0 20px 10px; position: relative; z-index: 4; width: 330px;}

.search-form input::-webkit-input-placeholder {color: #AAAAAA;}
.search-form .rw-input::-webkit-input-placeholder {color: #AAAAAA !important;}
.search-form .react-tags {border: 1px solid #e0e0e0 !important;}
.search-form .react-tags:hover {border: 1px solid #e0e0e0 !important;}
.search-form .react-tags {border: 1px solid #e0e0e0 !important;}
.search-form .rw-multiselect-wrapper .rw-input {display: none;}
.search-form label {font-size: 13px; margin-right: 16px; float: left;}
.search-form label input[type="checkbox"] {float: left; margin-top: 3px; margin-bottom: 4px;}

.form-section {
  margin-bottom: 15px !important;
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid $divider;

  .search-input-inline {width: 60px !important; height: 32px; padding: 0 4px; box-sizing: border-box;}
  .search-input-inline-label {margin: 0 8px !important;}

  .rw-widget-container {background: transparent; border: none;}
  .react-tags.rw-widget {padding: 0;}
  .rw-multiselect-wrapper {min-height: 40px !important;}
  .rw-input {height: 40px !important; width: 100% !important;}
  .rw-open {box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06); border: 1px solid #e0e0e0 !important;}
  .rw-state-focus {outline: none !important; border: none !important;}
  .rw-multiselect-taglist > li {font-weight: 400; font-size: 13px;}
  .children-label {font-size: 11px; text-transform: uppercase; font-weight: 600;}
}

.form-input-inline {display: inline-block; margin-left: 10px;}
.form-input-inline:first-of-type {margin-left: 0;}
.form-section:last-child {border-bottom: 0;}

.search-dropdown-box-footer {background-color: #fff; border-top: 1px solid #e3e3e3; padding: 10px 20px; text-align: right;}

.search-form-container {position: relative;}
.search-form-container #form {overflow: hidden; position: absolute; width: 100%; z-index: 4; background: #f5f5f5;}

.filter-children {clear: both; margin-top: 40px;}

/***** SEARCH FORM TRANSITIONS *****/
.searchFormTransition-enter {
     top: -854px;
     opacity: .5;
   }

.searchFormTransition-enter.searchFormTransition-enter-active {
  top: 0;
  opacity: 1;
  transition: all 600ms ease-in-out;
}

.searchFormTransition-exit {
  top: 0;
  opacity: 1;
}

.searchFormTransition-exit.searchFormTransition-exit-active {
  top: -854px;
  opacity: .5;
  transition: all 600ms ease-in-out;
}

/***** TWO COLUMN CHECKS STYLES *****/
.filter-types label, .filter-ethnicity label {width: 50%; float: left; margin-bottom: 12px; margin-right: 0;}

/***** THREE COLUMN CHECKS STYLES *****/
.filter-languages label, .filter-rb label {width: 33.3%; float: left; margin-bottom: 12px; margin-right: 0;}