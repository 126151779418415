/***** RESUME HEADER STYLES ****/
.page-count {float: right; position: relative; right: 184px; overflow: auto; top: 4px;}
.page-count .panel-action {margin-right: 8px;}
.count:hover {color: #727272 !important;}


@media only screen and (min-width: 1400px){
	.page-count {right: 712px;}
  .sidebar-hidden .page-count {right: 140px;}
}

@media only screen and (min-width: 1200px) and (max-width: 1400px){
	.page-count {right: 712px;}
	.sidebar-hidden .page-count {right: 180px;}
}

@media only screen and (min-width: 1000px) and (max-width: 1200px){
  .page-count {right: 216px;}
}

@media only screen and (min-width: 720px) and (max-width: 1000px){
  .page-count {right: 220px;}
}

@media only screen and (max-width: 720px){
  .page-count {right: 0; top: 0;}
}

@media only screen and (max-width: 575px){
	.page-count {float: none; text-align: center; background: #f5f5f5; margin: 8px -20px -8px -20px; padding: 4px 0;}
	.resumes-panel-actions-fixed {padding-bottom: 8px !important; padding-top: 4px !important; background: #DCDBDD;}
}

@media only screen and (max-width: 400px){
  .page-count {float: none; margin-top: 4px;}
}

/***** RESUME CONTAINER STYLES *****/
.create-resume-container {max-width: 850px;}

@media only screen and (max-width: 1200px){
	.create-resume-container {padding-right: 15px;}
}
@media (min-width: 544px) and (max-width: 768px){
	.create-resume-container {max-width: 650px;}
}

/***** RESUME COLUMN STYLES *****/
.resume-column-left {width: 50%; margin-right: 5%; float: left;}
.resume-column-right {width: 45%; float: left;}

/***** RESUME SECTION TEXT STYLES *****/
.resumePage .resume-title-header {
	border-bottom: 2px solid $divider; padding-bottom: 5px; margin-bottom: 15px;
	h3 {font-size: 15px; font-weight: 600; color: $secondary-text; display: block; line-height: 24px; margin-bottom: 0; padding-bottom: 0;}
	.validation-error {font-size: 11px; font-weight: 600; color: #fff; display: block; background: red; padding: 2px 10px; border-radius: 3px; text-transform: uppercase; margin-top: 5px;}
}

/***** RESUME PERSONAL INFO STYLES *****/
.language {display: inline-block;}
.language:after {content: ","; margin-right: 4px;}
.language:last-child:after {content: ""; margin-right: 0;}

/***** RESUME TYPE STYLES *****/
.type-amount {position: relative; text-align: right;}
.type-amount .fa-circle {margin-right: 3px; color: $background-dark-medium; transition: all 300ms linear;}
.type-amount .fa-times {float: right; line-height: normal; position: absolute; right: -20px; top: 12.5px; color: $accent-color-3; transition: all 300ms linear;}
.type-amount .fa-times:hover {color: $accent-color-2; cursor: pointer;}
.filled {color: $accent-color !important;}

/***** RESUME PICTURE SECTION *****/
.resume-photos {clear: both;}
.resume-pic {height: 50px; width: 50px; margin-right: 10px; border-radius: 3px; overflow: hidden; display: inline-block;}
.resume-pic img {height: auto; width: 100%;}
.resume-photos .dragDrop {margin-top: 16px;}
.resume-photos .dragDropFileBtn{width: 70% !important;}


/***** RESUME REFERENCES STYLES *****/
.reference-relationship {font-size: 13px; font-weight: normal; margin-left: 8px;}

/***** RESUME TIMELINE STYLES ****/
.resume-timeline {border-left: 1px solid $divider; padding-left: 20px; position: relative; padding-bottom: 16px; margin-left: 8px; margin-bottom: 0 !important;}
.resume-timeline:last-child {border-left: none; margin-top: -4px;}
.resume-timeline:last-child .resume-circle {margin-top: 4px;}
.resume-timeline p {margin-bottom: 0;}
.resume-timeline p:first-of-type {margin-top: -2px !important; vertical-align: super;}
.resume-timeline-margin-bottom {margin-bottom: 10px;}
.resume-circle {position: absolute; left: -6px; top: -1px; color: #8e8e8e; font-size: 12px;}


/***** MEDIA QUERIES *****/

/***** RESUME TABLE - TITLE STYLES *****/
@media only screen and (min-width: 1340px) and (max-width: 1500px){
	.resume-table-title {min-width: unset !important; width: 95px;}
}

@media only screen and (min-width: 1000px) and (max-width: 1150px){
	.resume-table-title {min-width: unset !important; width: 95px;}
}

@media only screen and (min-width: 610px) and (max-width: 785px){
	.resume-table-title {min-width: unset !important; width: 82px;}
}

@media only screen and (max-width: 435px){
	.resume-table-title {min-width: unset !important; width: 95px;}
}


/***** SINGLE COLUMN RESUME *****/
@media (min-width: 1201px) and (max-width: 1340px){
	.single-resume-content{
		.resume-column-left {width: 100%; margin-right: 0; float: none;}
		.resume-column-right {width: 100%; float: none;}
		.sidebar-closed .resume-column-left {width: 50%; margin-right: 10%; float: left;}
		.sidebar-closed .resume-column-right {width: 40%; float: left;}
	}
}

@media (min-width: 720px) and (max-width: 1000px){
	.single-resume-content{
		.resume-column-left {width: 100%; margin-right: 0; float: none;}
		.resume-column-right {width: 100%; float: none;}
		.sidebar-closed .resume-column-left {width: 50%; margin-right: 10%; float: left;}
		.sidebar-closed .resume-column-right {width: 40%; float: left;}
	}
}

@media only screen and (max-width: 610px){
	.single-resume-content{
		.resume-column-left {width: 100%; margin-right: 0; float: none;}
		.resume-column-right {width: 100%; float: none;}
		.sidebar-closed .resume-column-left {width: 50%; margin-right: 10%; float: left;}
		.sidebar-closed .resume-column-right {width: 40%; float: left;}
	}
}

/***** HIDE TEXT ON RESUMES SUBHEADER *****/
@media only screen and (min-width: 720px) and (max-width: 800px){
	.resumes-panel-actions-fixed .panel-action p {display: none;}
	.all-resumes p {display: inline-block !important;}
	.mobile-popup {display: block !important;}
}

@media only screen and (max-width: 665px){
	.resumes-panel-actions-fixed .panel-action p {display: none;}
	.panel-actions-fixed .panel-action p {display: none;}
	.all-resumes {border-right: 1px solid $divider; padding-right: 16px;}
	.panel-action-button {background: transparent !important; color: $secondary-text !important; padding: 0 !important;}
	.panel-action-button:hover {background: transparent; color: $primary-color !important;}
}

@media only screen and (min-width: 400px) and (max-width: 600px){
.all-resumes p {display: inline-block !important;}
}

/***** SHARE RESUME MODAL *****/
.share-resume-modal-header {@extend .share-modal-header;}

@media only screen and (max-width: 544px){
	.share-resume-modal-header p {width: calc(100% - 56px);}
	.share-modal-header p {width: calc(100% - 56px);}
}




