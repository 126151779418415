.search-filter {
  background: $primary-color-dark;
  padding: 0 20px;
  position: relative;
  z-index: 5;

  .search-bar {position: relative;}
  .search {
    position: relative;
    padding: 20px 0;

    input[type="text"] {
      color: $secondary-text;
      display: inline-block;
      width: 100%;
      padding: 6px 20px;
      font-size: 12px;
      font-weight: 400;
      text-indent: 5px;
      border: 0;
      background-color: #fff;
      box-shadow: none;
    }

    .btn-search-filter {
      width: 5%;
      color: #fff;
      margin-left: 5%;
      text-align: right;
      transition: all 300ms linear;
      //text-align: center;
      //border-radius: 3px;
      //padding: 7.5px 5px;
      //background: #fff;
    }
    .btn-search-filter:hover {cursor: pointer; color: $primary-color;}
    .btn-search-filter-active {color: $accent-color-2;}
  }
  .search-with-filter input[type="text"] {width: 90%;}
}

.filter-bar{
  color: $secondary-text;
  padding: 4px 20px;
  position: relative;
  z-index: 5;

  .filter-column-read {font-size: 10px;}
  .filter-column-read .active {color: $accent-color;}
  .filter-column-favorite {text-align: right; font-size: 12px;}
  .filter-column-favorite .active {color: $accent-color-2;}
  .filter-column-dropdown {
    p {display: inline-block; font-size: 12px; margin: 0 11px 0 0; width: 48px;}
    .menu {border-radius: 3px !important; }
    .text {font-size: 12px;}
    .item {font-size: 12px !important;}

    .mui-select {margin: 0; padding: 0; display: inline-block; width: calc(100% - 60px); background: transparent; border: none; font-size: 12px; line-height: 14px; color: $secondary-text; box-shadow: none;}
    //.mui-select .mui-select__menu {border-radius: 3px; left: 0; right: auto; min-width: 130px; height: auto !important; top: 5px !important; padding: 0;}
    //.mui-select .mui-select__menu .option {font-size: 12px; color: $secondary-text;}
    .inbox-settings-select .option {font-size: 12px;}
  }
}

.search-form-container {border-top: 1px solid $divider; display: block;}

.group-filter {padding-left: 24px;}

.search-filter-full {
  .search-bar {padding-top: 3px;}
  .search {border-radius: 3px; margin-top: 8px; padding: 0;}
}

.sidebar-list-results {
  background: $accent-color-2;
  padding: 7px 20px;
  font-size: 10px;
  color: #fff;
  overflow: auto;

  p {width: 46.5%; float: left; margin: 0;}
  p:first-child {margin-left: 7%;}
  p:last-child {text-align: right;}
  p:last-child span {transition: all 300ms linear;}
  p:last-child:hover span {cursor: pointer; color: $secondary-text;}
  .sidebar-list-results-bold {font-weight: 600;}
}


@media only screen and (max-width: 720px){
  .search-filter .search-bar {
    padding-top: 0;
  }
  .search-filter .search {
    padding: 2px 0 4px 0;
  }
}