.react-notification-center {
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 99999;
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;

    *, *:before, *:after {
        box-sizing: border-box;
    }

    .r-notifications-icon {
        width: 10px;
        height: 10px;
        border: 1px solid white;
        border-radius: 50px;
        text-align: center;
        line-height: 20px;
        color: white;
        position: absolute;
        z-index: 0;
        font-size: 10px;
        cursor: pointer;
        background-color: #ccc;
        top: 6px;
        left: 6px;
        overflow: hidden;
        transition: all .2s ease-in-out;

        &.active {
            background-color: #ff5c5c;
            width: 22px;
            height: 22px;
            top: 1px;
            left: 1px;
        }

        &:hover {
            transform: scale(1.1);
        }

        &.pulse {
            animation-name: pulse_animation;
            animation-duration: 300ms;
            animation-iteration-count: 2;
            animation-timing-function: linear;

            -webkit-animation-name: webkit_pulse_animation;
            -webkit-animation-duration: 300ms;
            -webkit-animation-iteration-count: 2;
            -webkit-animation-timing-function: linear;
        }
    }

    .rn-header {
        width: 100%;
        height: 50px;
        background-color: #fcfcfc;
        border-bottom: 1px solid #f0f0f0;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        overflow: hidden;
        line-height: 50px;
        font-size: 16px;
        color: $accent-color;
        font-family: "Open Sans";
        font-weight: 600;
        text-align: center;
    }

    .rn-content {
        width: 100%;
        height: 360px;
        background-color: white;
        overflow: hidden;
        overflow-y: auto;
        font-size: 14px;

        .no-rn {
            width: 100%;
            height: 100%;
            text-align: center;
            color: #999;
            line-height: 250px;
            overflow: hidden;
        }
    }

    .rn-footer {
        width: 100%;
        height: 40px;
        background-color: #fcfcfc;
        border-top: 1px solid #f0f0f0;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        overflow: hidden;
    }

    .rr-wrapper {
        box-shadow: 3px 3px 25px #dbdbdb;
        border-radius: 3px;
        width: 350px;
        height: 450px;
        position: absolute;
        z-index: 1;

        &.left {
            top: 40px;
            left: -25px;
        }

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-right: 15px solid transparent;
            border-left: 15px solid transparent;
            border-bottom: 15px solid #fcfcfc;
            position: absolute;
            top: -15px;
            left: 21px;
        }

        .notification-holder {
            width: 100%;
            height: 100%;
            position: absolute;
            overflow: hidden;
            border-radius: 3px;
        }
    }

    &.light-theme {
        .notification-box {
            background-color: white;
        }

        .notification-list {
            .header {
                border-bottom: 1px solid #f0f0f0;
                h4 {
                    color: #666;
                }
            }

            .contents {
                li.item {
                    border-bottom: 1px solid #f9f9f9;
                    .short-desc {
                        color: #444;
                    }
                }
            }

            .footer {
                border-top: 1px solid #f0f0f0;
            }
        }
    }
}

@-webkit-keyframes webkit_pulse_animation {
    0% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.2); }
    100% { -webkit-transform: scale(1); }
}

@keyframes pulse_animation {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

.r-notification {
    width: 100%;
    height: 450px;
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 2;
    transform: translate(-400px, 0);
    transition: all .3s ease-in-out;

    &.active {
        transform: translate(0, 0);
    }

    .desc {
        padding: 20px;
    }

    button {
        display: block;
        width: 50px;
        height: 30px;
        margin: auto;
        margin-top: 4px;
        background-color: transparent;
        border: none;
        outline: none;

        &:hover {
            cursor: pointer;
        }

        .back {
            display: block;
            width: 40px;
            height: 4px;
            background-color: #ccc;
            position: relative;
            border-radius: 4px;

            &:before {
                content: '';
                width: 0;
                height: 0;
                border-top: 7px solid transparent;
                border-right: 7px solid #ccc;
                border-bottom: 7px solid transparent;
                position: absolute;
                top: -5px;
                left: -5px;
            }
        }
    }
}
.r-notifications {
    width: 100%;
    height: 450px;
    overflow: hidden;
    position: relative;
    z-index: 0;

    ul.rn-ul {
        padding: 0;
    }

    li.rn-item {
        width: 100%;
        padding: 10px 0;
        list-style: none;
        cursor: pointer;
        position: relative;
        border-bottom: 1px solid #f5f5f5;

        &:before {
            display: none;
            content: '';
            width: 5px;
            height: 5px;
            position: absolute;
            top: 50%;
            left: 8px;
            background-color: #666;
            border-radius: 50%;
        }

        &:hover {
            background-color: #fcfcfc;
            box-shadow: inset 0px 0px 2px #f2f2f2;
        }

        &.new {
            &:before {
                display: block;
            }
        }

        &:last-child {
            border: none;
        }

        .short-desc {
            width: 80%;
            margin: 5px auto;

            .notification-tag {
                color: white;
                border-radius: 16px;
                padding: 3px 7px;
                font-size: 11px;
                text-transform: uppercase;
                margin-right: 4px;
                line-height: 20px;

                &.info {
                    background-color: #58abc3;
                }

                &.success {
                    background-color: #60bb71;
                }

                &.warning {
                    background-color: #f7a336;
                }

                &.danger {
                    background-color: #db6a64;
                }
            }

            &:hover {
                .title {
                    text-decoration: underline;
                }
            }

            .date {
                display: block;
                font-size: 10px;
                color: #999;
                clear: both;
                margin-top: 5px;
            }

        }
    }
}

.clear-all {font-size: 11px; margin-top: 12px; float: right; margin-right: 8px;}
.clear-all:hover {transition: all 300ms linear; cursor: pointer; color: $accent-color;}